const API = {
  // BASE_URL: "https://api-dev.adfw.com/api/",
  // BASE_URL: "https://api-uat.adfw.com/api/",
  BASE_URL: "https://api-prod.adfw.com/api/",

  //SPONSORS
  GET_SPONSOR_BY_ID: "sponsors/getByUUID/", //METHOD: GET
  UPDATE_SPONSORS: "updateSponsor/", //METHOD: POST

  UPLOAD: "uploadImageorPdf",
};

export default API;
