import React, { useEffect, useState } from "react";
import ImagePicker from "../../components/imagePicker";
import {
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  message,
  Result,
  Select,
  SelectProps,
} from "antd";
import { Col, Row } from "react-bootstrap";
import { GET, PUT } from "../../utils/apiCalls";
import API from "../../config/apis";
import TextArea from "antd/es/input/TextArea";
import { useParams } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import HomeIntro from "./components/home-intro";
import { RiCheckboxCircleFill } from "react-icons/ri";
import { PhoneNumberUtil } from "google-libphonenumber";

import CountryJson from "../../config/country.json";

import { FaExclamationCircle } from "react-icons/fa";

function Step2(props: any) {
  const { sponsorId } = useParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [isLoading, setisLoading] = useState<boolean>(true);

  const [data, setData] = useState<any>();
  const [showMessage, setShowMessage] = useState<any>();

  const [img_url, setImg_Url] = useState<string | undefined>();
  const [img2_url, setImg2_Url] = useState<string | undefined>();
  const [img3_url, setImg3_Url] = useState<string | undefined>();

  // const [videolink, setVideoLink] = useState<boolean>(false);

  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    setisLoading(true);
    try {
      let response = await GET(API.GET_SPONSOR_BY_ID + sponsorId);
      if (response?.status) {
        setData(response?.data);
        setisLoading(false);
        // setVideoLink(response?.data?.categories?.is_video_link);
        setImg_Url(response?.data?.horizontal_logo);
        setImg2_Url(response?.data?.vertical_logo);
        setImg3_Url(response?.data?.brand_guidlinesImage);
      }
      const sponsorSection = document.querySelector(".heading");
      if (sponsorSection) {
        sponsorSection.scrollIntoView({ behavior: "smooth" });
      }
    } catch (error: any) {
      console.log(error);
      setisLoading(false);
    } finally {
      setisLoading(false);
    }
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      const obj = {
        company_name: values.company_name,
        company_url: values.company_url,
        company_brief: values.company_brief,
        contact_person_name: values?.contact_person_name,
        contact_person_email: values?.contact_person_email,
        contact_person_phone: values?.contact_person_phone,
        contact_person_country_code:
          values?.contact_person_country_code?.split("-")[0],
        contact_person_designation: values?.contact_person_designation,
        sectors_id: values?.sectors_id,
        orderId: data?.orderId?.orderId,
        isEdited: true,
        video_file_link: values?.video_file_link,
        video_link: values?.video_link,
        horizontal_logo: img_url,
        vertical_logo: img2_url,
        brand_guidlinesImage: img3_url,
      };
      const api = API.UPDATE_SPONSORS + data?.id;
      const res = await PUT(api, obj);
      if (res.status) {
        setShowMessage(res.status);
        message.success("Sponsor updated successfully!");
        getData();
      } else {
        message.error("Failed to update sponsor.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      message.error("Error submitting form. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // const validateLinks = (_: any, value: any) => {
  //   const { video_file_link, contact_person_designation } =
  //     form.getFieldsValue();
  //   if (!video_file_link && !contact_person_designation) {
  //     return Promise.reject(new Error("At least one link is required"));
  //   }

  //   return Promise.resolve();
  // };
  const validateFormFields = (_: any, value: any) => {
    const { image1, image2 } = form.getFieldsValue();
    if (!image1 && !image2) {
      return Promise.reject(new Error("At least one logo is required"));
    }
    return Promise.resolve();
  };
  const validatePhoneNumber = (phoneNumber: string) => {
    try {
      const phoneUtil = PhoneNumberUtil.getInstance();
      if (!phoneNumber) {
        throw new Error("Phone number is missing.");
      }
      const parsedNumber = phoneUtil.parse(phoneNumber);
      const isValid = phoneUtil.isValidNumber(parsedNumber);
      if (isValid) {
        return { status: true };
      } else {
        return { status: false };
      }
    } catch (error) {
      console.error("Error parsing phone number:", error);
      return { status: false };
    }
  };
  const PrefixSelector = () => {
    const [selectedCountry, setSelectedCountry] = useState({
      code: "971",
      flag: "AE",
    });
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    type LabelRender = SelectProps["labelRender"];
    const labelRender: LabelRender = (props) => {
      const { value } = props;
      return (
        <div className="d-flex justify-content-between align-items-center">
          <DownOutlined style={{ fontSize: 13, color: "#000" }} />
          <img
            alt={selectedCountry?.code}
            src={`https://flagsapi.com/${selectedCountry?.flag}/flat/24.png`}
          />
          +{selectedCountry.code}
          <div></div>
        </div>
      );
    };

    const customFilter = (input: string, option: any) => {
      const [code, country] = option.value.split("-");
      return (
        option?.key?.toLowerCase().includes(input.toLowerCase()) ||
        code.includes(input)
      );
    };

    const onChangeSelect = (value: string) => {
      const [code, countryName] = value.split("-");
      const country = CountryJson.find((item) => item.country === countryName);

      if (country) {
        setSelectedCountry({
          code: country.code.toString(),
          flag: country.flag,
        });
      } else {
        setSelectedCountry({ code: "971", flag: "AE" });
      }
    };

    return (
      <Form.Item
        noStyle
        name="contact_person_country_code"
        rules={[{ required: true, message: "Please select country code" }]}
      >
        <Select
          style={{ width: 120 }}
          variant="borderless"
          showSearch
          className="sponsor-select2"
          size="large"
          labelRender={labelRender}
          suffixIcon={false}
          value={selectedCountry.code}
          onDropdownVisibleChange={(open) => setIsDropdownOpen(open)}
          optionFilterProp="children"
          filterOption={customFilter}
          onChange={onChangeSelect}
          dropdownStyle={{ width: 250 }}
          optionLabelProp="label"
        >
          {CountryJson.map((item) => (
            <Select.Option
              key={item.country}
              value={`${item.code}-${item.country}`}
              label={
                <img
                  alt={item.code.toString()}
                  src={`https://flagsapi.com/${item.flag}/flat/24.png`}
                />
              }
            >
              <div className="d-flex gap-2 align-items-center">
                <img
                  alt={item.code.toString()}
                  src={`https://flagsapi.com/${item.flag}/flat/24.png`}
                />
                +{item.code} &nbsp;
                {item.country}
              </div>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    );
  };

  return (
    <div>
      {isLoading ? (
        <Card loading={isLoading}></Card>
      ) : (
        <div>
          {data?.id ? (
            <div>
              {!data?.isEdited ? (
                <div>
                  <HomeIntro />
                  <br />
                  <Card bodyStyle={{ backgroundColor: "#fafdff" }}>
                    <Form
                      form={form}
                      onFinish={onFinish}
                      scrollToFirstError={{
                        behavior: "smooth",
                        block: "center",
                        inline: "nearest",
                      }}
                      initialValues={{
                        company_name: data?.company_name,
                        company_url: data?.company_url,
                        company_brief: data?.company_brief,
                        contact_person_name: data?.contact_person_name,
                        contact_person_email: data?.contact_person_email,
                        contact_person_phone: data?.contact_person_phone,
                        contact_person_country_code:
                          data?.contact_person_country_code || "971",
                        contact_person_designation:
                          data?.contact_person_designation,
                        video_file_link: data?.video_file_link,
                        video_link: data?.video_link,

                        image1: data?.horizontal_logo,
                        image2: data?.vertical_logo,
                        image3: data?.brand_guidlinesImage,
                      }}
                    >
                      <Row>
                        <div className="sponsers-title">Company Info</div>
                        <Col md={6}>
                          <Form.Item
                            name="company_name"
                            rules={[
                              {
                                required: true,
                                message: "Please enter your company name",
                              },
                            ]}
                            style={{ marginBottom: 40 }}
                            className="sponsors-input"
                          >
                            <Input
                              bordered={false}
                              size="large"
                              placeholder="Name of company"
                            />
                          </Form.Item>
                        </Col>
                        <Col md={6}>
                          <Form.Item
                            name="company_url"
                            rules={[
                              {
                                required: true,
                                message: "Please enter your website link",
                              },
                              {
                                type: "url",
                                message: "Please enter a valid URL",
                              },
                            ]}
                            style={{ marginBottom: 40 }}
                            className="sponsors-input"
                          >
                            <Input
                              bordered={false}
                              size="large"
                              placeholder="Company website"
                            />
                          </Form.Item>
                        </Col>
                        <Col md={12}>
                          <Form.Item
                            name="company_brief"
                            style={{ marginBottom: 40 }}
                            className="sponsors-input"
                            rules={[
                              {
                                required: true,
                                message: "Please enter your company brief",
                              },
                            ]}
                          >
                            <TextArea
                              bordered={false}
                              size="large"
                              rows={5}
                              placeholder="Company brief"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <div className="sponsers-title">
                          Contact person for sponsorship
                        </div>
                        <Col md={6}>
                          <Form.Item
                            name="contact_person_name"
                            rules={[
                              {
                                required: true,
                                message: "Please enter your contact name",
                              },
                            ]}
                            style={{ marginBottom: 40 }}
                            className="sponsors-input"
                          >
                            <Input
                              bordered={false}
                              size="large"
                              placeholder="Name"
                            />
                          </Form.Item>
                        </Col>
                        <Col md={6}>
                          <Form.Item
                            name="contact_person_email"
                            rules={[
                              {
                                required: true,
                                type: "email",
                                message: "Please enter a valid email",
                              },
                            ]}
                            style={{ marginBottom: 40 }}
                            className="sponsors-input"
                          >
                            <Input
                              bordered={false}
                              size="large"
                              placeholder="Email"
                            />
                          </Form.Item>
                        </Col>
                        <Col md={6}>
                          <Form.Item
                            name="contact_person_phone"
                            style={{ marginBottom: 40 }}
                            className="sponsors-input"
                            rules={[
                              {
                                required: true,
                                message:
                                  "Please enter your contact person phone",
                              },
                              {
                                validator: (_, value) => {
                                  const countryCode = form
                                    .getFieldValue(
                                      "contact_person_country_code"
                                    )
                                    ?.split("-")[0];
                                  const isValid = validatePhoneNumber(
                                    `+${countryCode}${value}`
                                  );
                                  if (!value) {
                                    return Promise.reject();
                                  }
                                  if (!isValid.status) {
                                    return Promise.reject(
                                      new Error(
                                        "Please enter a valid phone number"
                                      )
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ]}
                          >
                            <Input
                              addonBefore={<PrefixSelector />}
                              type="text"
                              variant="borderless"
                              placeholder="52 123 4567"
                              size="large"
                              style={{ paddingBottom: 5 }}
                              onChange={(e) => {
                                const value = e.target.value.replace(/\D/g, "");
                                form.setFieldValue(
                                  "contact_person_phone",
                                  value
                                );
                              }}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col md={6} style={{ marginTop: "5px" }}>
                          <Form.Item
                            name="contact_person_designation"
                            style={{ marginBottom: 40 }}
                            className="sponsors-input"
                            rules={[
                              {
                                required: true,
                                message:
                                  "Please enter your contact person designation",
                              },
                            ]}
                          >
                            <Input
                              bordered={false}
                              size="large"
                              placeholder="Designation"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <br />
                      {/* {videolink ? ( */}
                      <>
                        <div className="sponsers-title">
                          Links to your corporate video (16:9 format, 1080p
                          resolution)
                        </div>
                        <Row>
                          <Col sm={6} xs={12}>
                            <Form.Item
                              name="video_file_link"
                              style={{ marginBottom: 40 }}
                              className="sponsors-input"
                              rules={[
                                // {
                                //   validator: validateLinks,
                                // },
                                {
                                  type: "url",
                                  message: "Please enter a valid URL",
                                },
                              ]}
                            >
                              <Input
                                bordered={false}
                                size="large"
                                placeholder="File Transfer Link (upto 5 videos)"
                              />
                            </Form.Item>
                          </Col>
                          <Col sm={6} xs={12}>
                            <Form.Item
                              name="video_link"
                              style={{ marginBottom: 40 }}
                              className="sponsors-input"
                              // rules={[
                              //   {
                              //     validator: validateLinks,
                              //   },
                              // ]}
                            >
                              <Input
                                bordered={false}
                                size="large"
                                placeholder="Video Link (Youtube/Vimeo)"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                      {/* ) : null} */}
                      <div className="sponsers-title">Uploads</div>
                      <Row>
                        <Col md={6}>
                          <Form.Item
                            name="image1"
                            rules={[
                              {
                                validator: validateFormFields,
                              },
                            ]}
                          >
                            {img_url ? (
                              <div className="sponsors-view-log">
                                <>
                                  <span>Logo on file</span>:{" "}
                                  <a href={img_url} target="_blank">
                                    View Logo
                                  </a>
                                </>
                              </div>
                            ) : null}
                            <ImagePicker
                              label={"Horizontal logo (Open file)"}
                              onChange={(value: any) => {
                                setImg_Url(value?.url);
                                form.setFieldValue("image1", value?.url);
                                form.validateFields(["image1", "image2"]);
                              }}
                              fileURL={img_url}
                              size={3145728}
                              formats={[
                                "application/pdf",
                                "application/postscript",
                              ]}
                              onLoading={(status: any) => setUploading(status)}
                            />
                            <div className="sponsors-upolad-label">
                              <div>Allowed file types: .ai, .pdf</div>
                              <div>
                                <div>Max size: 3 MB</div>
                              </div>
                            </div>
                          </Form.Item>
                        </Col>
                        <Col md={6}>
                          <Form.Item
                            name="image2"
                            rules={[
                              {
                                validator: validateFormFields,
                              },
                            ]}
                          >
                            {img2_url ? (
                              <div className="sponsors-view-log">
                                <>
                                  <span>Logo on file</span>:{" "}
                                  <a href={img2_url} target="_blank">
                                    View Logo
                                  </a>
                                </>
                              </div>
                            ) : null}
                            <ImagePicker
                              label={"Vertical Logo (Open file)"}
                              onChange={(value: any) => {
                                setImg2_Url(value?.url);
                                form.setFieldValue("image2", value?.url);
                                form.validateFields(["image1", "image2"]);
                              }}
                              fileURL={img2_url}
                              size={3145728}
                              formats={[
                                "application/pdf",
                                "application/postscript",
                              ]}
                              onLoading={(status: any) => setUploading(status)}
                            />
                            <div className="sponsors-upolad-label">
                              <div>Allowed file types: .ai, .pdf</div>
                              <div>
                                <div>Max size: 3 MB</div>
                              </div>
                            </div>
                          </Form.Item>
                        </Col>
                        <Col md={6}>
                          <br />
                          <Form.Item name="image3" noStyle>
                            {img3_url ? (
                              <div className="sponsors-view-log">
                                <>
                                  <span>Logo on file</span>:{" "}
                                  <a href={img3_url} target="_blank">
                                    View Guidlines
                                  </a>
                                </>
                              </div>
                            ) : null}
                            <ImagePicker
                              label={"Brand guidlines (optional)"}
                              onChange={(value: any) => {
                                setImg3_Url(value?.url);
                                form.setFieldValue("image3", value?.url);
                              }}
                              fileURL={img3_url}
                              size={3145728}
                              formats={[
                                "application/msword",
                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                "application/pdf",
                              ]}
                              onLoading={(status: any) => setUploading(status)}
                            />
                            <div className="sponsors-upolad-label">
                              <div>Allowed file types: .doc, docx, pdf</div>
                              <div>
                                <div>Max size: 3 MB</div>
                              </div>
                            </div>
                          </Form.Item>
                        </Col>
                      </Row>
                      <br />
                      <Form.Item
                        name="agree"
                        valuePropName="checked"
                        rules={[
                          {
                            required: true,
                            validator: (_, value) =>
                              value
                                ? Promise.resolve()
                                : Promise.reject(
                                    new Error(
                                      "You must agree to the terms and conditions"
                                    )
                                  ),
                          },
                        ]}
                      >
                        <Checkbox>
                          *I have read and agree to the
                          <a
                            className="link-text"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.adgm.com/information/privacy-policy"
                          >
                            Privacy Policy
                          </a>
                          and
                          <a
                            className="link-text"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.adgm.com/information/terms-and-conditions"
                          >
                            Terms and conditions
                          </a>
                          consent to the processing of my data
                        </Checkbox>
                      </Form.Item>
                      <br /> <br />
                      <Form.Item>
                        <Button
                          disabled={uploading ? true : false}
                          type="primary"
                          htmlType="submit"
                          block
                          loading={loading}
                          style={{
                            height: 50,
                            fontWeight: "bold",
                          }}
                        >
                          SUBMIT
                        </Button>
                      </Form.Item>
                    </Form>
                  </Card>
                </div>
              ) : (
                <div style={{ marginTop: "5%" }}>
                  <Result
                    icon={
                      <div className="sponsers-success">
                        <RiCheckboxCircleFill color="#346ace" size={82} />
                      </div>
                    }
                    title={
                      <div className="sponsers-success-title">
                        {showMessage
                          ? "Thank you"
                          : "You have already filled out this form."}
                      </div>
                    }
                    subTitle={
                      <div className="sponsers-success-subTitle">
                        {showMessage
                          ? `The details shared have been saved on our system and we will
          be in touch for any further information.`
                          : null}
                      </div>
                    }
                  />
                </div>
              )}
            </div>
          ) : (
            <div style={{ marginTop: "5%" }}>
              <Result
                icon={
                  <div className="sponsers-success">
                    <FaExclamationCircle color="#346ace" size={82} />
                  </div>
                }
                title={"Sorry page not found"}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Step2;
