import React from "react";
const HomeIntro = () => {
  return (
    <div>
      <div className="home-text2">
        We are delighted to welcome all our valuable sponsors and partners as we
        embark on an exciting journey in delivering a spectacular Abu Dhabi
        Finance Week - 2024 edition. To ensure we position your company in the
        best possible way, map out all your partnership deliverables and
        streamline the onboarding process, we have created this online platform
        to simplify the communication.
      </div>
      <br />
      <div className="home-text2">
        As the first step, we kindly request you to provide the below mentioned
        details. Please note the details provided here will be considered as the
        final versions and will be used across all marketing, comms and outreach
        activities. Additionally, please refer to the{" "}
        <a href="https://bit.ly/adfw-sponsors-deck" target="_blank">
          attached sponsor placement guide
        </a>{" "}
        that will show you the position of your logo as well as a size reference
        with regards to your logo placement in line with your relevant tier of
        partnership.
      </div>
      <br />
      <div className="home-text2">
        Please, fill in this form within 3 business days.
      </div>
    </div>
  );
};

export default HomeIntro;
