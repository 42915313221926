import React, { useState } from "react";
import "./styles.scss";
import Header from "../../components/header";
import Footer from "../../components/footer";

import { FloatButton } from "antd";
import { Container } from "react-bootstrap";
import HomeIntro from "./components/home-intro";

import Step1 from "./step1";
import Step2 from "./step2";

const HomeScreen = () => {
  const [step, setIsStep] = useState(1);
  return (
    <div className="home">
      <Header />
      <div className="main-body">
        <div className="home-box">
          <Container>
            <div className="home-text1">
              Sponsor & Partner Onboarding Portal
            </div>
          </Container>
        </div>

        <Container>
          <div className="home-box2">
            <br /> <br /> <br />
            <br />
            <Step2 onSuccess={() => setIsStep(1)} />
            <div style={{ margin: 70 }} />
          </div>
        </Container>
        <Footer />
      </div>
      <FloatButton.BackTop type="primary" visibilityHeight={500} />
    </div>
  );
};

export default HomeScreen;
